import React, { useState, useEffect } from 'react';
import './Publications.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTh, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { publications } from '../data/publications';


// Component to render a single publication
const Publication = ({ item, viewType }) => (
  <div className={`publication ${viewType}`}>
    <a href={item.link} target="_blank" rel="noopener noreferrer" className="icon-link">
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </a>
    <h2>{item.title}</h2>
    <p>{item.description}</p>
    <p>Published on: {item.publicationDate}</p>
    <div className="tags">
      {item.tags.map((tag, index) => (
        <span key={index} className="tag">{tag}</span>
      ))}
    </div>
  </div>
);

// Component to render all publications
const Publications = () => {
  const [viewType, setViewType] = useState('list'); // Initialize viewType to 'list'
  const [orderBy, setOrderBy] = useState('newest'); // Initialize orderBy to 'newest'
  const [sortedPublications, setSortedPublications] = useState([]); // Initialize sortedPublications to empty array

  const handleToggleView = () => {
    setViewType(viewType === 'list' ? 'grid' : 'list');
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  useEffect(() => {
    // Sort publications based on orderBy
    const sortedPublications = [...publications]; // Create a copy of the array
  
    if (orderBy === 'newest') {
      sortedPublications.sort((a, b) => b.publicationDate.localeCompare(a.publicationDate));
    } else if (orderBy === 'oldest') {
      sortedPublications.sort((a, b) => a.publicationDate.localeCompare(b.publicationDate));
    } 
  
    // Group sorted publications by month and year
    const groupedPublications = sortedPublications.reduce((acc, current) => {
      const date = new Date(current.publicationDate);
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      const key = `${month} ${year}`;
  
      if (!acc[key]) {
        acc[key] = [];
      }
  
      acc[key].push(current);
  
      return acc;
    }, {});
  
    const finalSortedPublications = Object.keys(groupedPublications)
      .map((key) => {
        return {
          key,
          publications: groupedPublications[key],
        };
      });
  
    setSortedPublications(finalSortedPublications);
  }, [orderBy]);


  return (
    <div className="container">
      <div className="header">
        <ToggleButtonGroup value={viewType} exclusive onChange={handleToggleView}>
          <ToggleButton value="grid">
              <FontAwesomeIcon icon={faTh} className='toggle-icon'/>
            </ToggleButton>
            <ToggleButton value="list">
              <FontAwesomeIcon icon={faBars} className='toggle-icon'/>
          </ToggleButton>
        </ToggleButtonGroup>
        <select value={orderBy} onChange={handleOrderByChange}>
          <option value="newest">Newest first</option>
          <option value="oldest">Oldest first</option>
        </select>
      </div>
      {sortedPublications.map((group, index) => (
        <div key={index} className="publication-group">
          <h2 className="group-header">{group.key}</h2>
          <div className={`publications ${viewType}`}>
            {group.publications.map((item) => (
              <Publication key={item.id} item={item} viewType={viewType} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Publications;