// Sample data for publications
export const publications = [
    /*{
      id: 1,
      title: 'Title of Article 1',
      description: 'Description of Article 1',
      publicationDate: '2022-01-01',
      link: 'https://www.article1.com',
      tags: ['Artificial Intelligence', 'Machine Learning'],
    },*/
    // ...
  ];