import React from 'react';
import './Home.css';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Profile from '../static/awards.jpg'

function Home() {
  return (
    <>
      <div className="home">
        <img src={Profile} alt="Profile" />
        <div className="name-and-title">
          <h2 className="name">Theo Walcot</h2>
          <p className="title">Software Developer, Product Expert and Entrepreneur</p>
          <div className="home-ctas">
            <button
              href={"../static/Theo_Walcot_CV.pdf"}
              download="Theo's CV"
              className="primary-button-home"
            >
              Download CV
            </button>
            <Link to="/products" className="secondary-button-home-link">
              <button className="secondary-button-home">My Products</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer styling="free" />
    </>
  );
}

export default Home;