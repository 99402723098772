import ApiCalendar from "react-google-calendar-api";

const config = {
  clientId: "106651858251303463432",
  apiKey: "AIzaSyBAXKs8-PE-Fu3ANZu1r72fBUok6jEppKY",
  scope: 'https://www.googleapis.com/auth/calendar.readonly',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
};

const apiCalendar = new ApiCalendar(config);

export const getEvents = async () => {
    try {
      await apiCalendar.handleClientLoad();
      // Wait for the Google API library to be loaded
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (window.gapi) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
      const response = await apiCalendar.listEvents({
        calendarId: 'everythingteddington@gmail.com',
        timeMin: (new Date()).toISOString(), // fetch events from now onwards
        singleEvents: true,
        orderBy: 'startTime',
      });

      const events = response.result.items.map((event) => ({
        title: event.summary,
        start: event.start.date || event.start.dateTime,
        end: event.end.date || event.end.dateTime,
      }));
  
      return events
      
    } catch (error) {
      console.error(error);
    }
  };