import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import './WebNavigation.css'

import { publications } from '../data/publications';


function WebNavigation() {
  const { pathname } = useLocation();

  const links = ['products', 'ventures', 'commitments', 'publications', 'about'];

  const updatedLinks = publications.length === 0 ? links.filter(link => link !== 'publications') : links;


  return (
    <div className="WebNavigation">
      <ul className="WebNavigationList">
        {updatedLinks.map((link) => (
          <li key={link} className="web-nav-item">
            <div className={`web-nav-background ${pathname === `/${link}` ? 'web-back-active' : ''}`}>
              <NavLink className="web-nav-link" to={`/${link}`}>
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </NavLink>
            </div>
          </li>
        ))}
      </ul>
      <div className='button-holder'>
        <a href={require("../static/Theo_Walcot_CV.pdf")} download="Theo's CV" className='primary-button-home-inner'>
          <button className='primary-button-home'>Download CV</button>
        </a>
      </div>
    </div>
  );
}

export default WebNavigation;