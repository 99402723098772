// Navigation.js

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";

import './Navigation.css';
import WebNavigation from "./components/WebNavigation";
import ResponsiveNavigation from "./components/ResponsiveNavigation";

function Navigation() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  useEffect(() => {
    if (isNavExpanded) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isNavExpanded]);

  return (
    <div className="whole-nav-container">
      <div className='home-container'>
        <NavLink className="nav-link" to="/" onClick={() => {setIsNavExpanded(false)}}>
          THEO WALCOT
        </NavLink>
      </div>
      <WebNavigation />
      <ResponsiveNavigation isNavExpanded={isNavExpanded} setIsNavExpanded={setIsNavExpanded} />
    </div>
  );
}

export default Navigation;
