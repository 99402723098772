/* EON_Next Images */
import eon_card from './ProductCardImages/eon-card.png';
import eon_main from './ProductPageImages/eon_main.68b7283bf18844893a92.png'
import eon_sub_1 from './ProductPageImages/eon_sub1.jpg';
import eon_sub_2 from './ProductPageImages/eon_sub2.jpg'
import eon_sub_3 from './ProductPageImages/eon_sub3.png'


/* Lolly Images */
import lolly_card from './ProductCardImages/lolly-card.png';
import lolly_main from './ProductPageImages/lolly_main.png';
import lolly_sub_1 from './ProductPageImages/lolly_sub2.png';
import lolly_sub_2 from './ProductPageImages/lolly_sub1.png'
import lolly_sub_3 from './ProductPageImages/lolly_sub3.png'

/* Diverr Images */
import diverr_card from './ProductCardImages/diverr-card.png';
import diverr_main from './ProductPageImages/diverr_main.jpeg';
import diverr_sub_1 from './ProductPageImages/diverr_sub2.jpeg';
import diverr_sub_2 from './ProductPageImages/diverr_sub1.jpeg'
import diverr_sub_3 from './ProductPageImages/diverr_sub3.jpeg'

const ProductsData = [
    {
        title: 'E.ON Next',
        description : 'Creating a customer focussed sustainable energy product whilst helping our most vulnerable customers',
        link : 'eon_next',
        img_card: eon_card,
        name :'eon_next',
        overview: 'I joined E.ON during the inception of E.ON Next, a new subsidiary dedicated to enhancing customer experiences through digital innovation. E.ON, a major player in the energy market, faced challenges from emerging competitors like Octopus, who redefined energy provision as a supportive, customer-centric service. E.ON sought to emulate this approach, recognizing the shift in consumer expectations. Despite its substantial user base, E.ON lacked customer advocacy, with most customers solely focused on price. As competition leveled pricing, fostering advocacy became a critical goal.',
        scope: `My work at E.ON Next encompassed three key areas. 
        Firstly, I focused on enhancing technological capabilities. E.ON had initially relied on external agencies to develop a basic platform, which was now showing signs of strain. Our mobile app had a dismal 1.1 rating across app stores, and the website faced similar issues with bugs and functionality. 
        Secondly, I spearheaded efforts to develop products that would enhance self-service options. A staggering 30% of our customers were resorting to calling our helplines over a two-week period, significantly higher than industry standards. This high volume of calls was not only straining our resources but also incurring significant costs for the business. 
        Lastly, I led initiatives to create products that would improve transparency with our customers and provide support to those most in need. This approach aimed to reduce our debt book while also enhancing the overall perception of the company among customers. These efforts were crucial in fostering customer advocacy and loyalty.`,
        role: 'In my role as a Technical Product Manager at E.ON Next, I actively engaged with development teams in an agile environment to drive the creation of customer-centric digital solutions. I collaborated closely with stakeholders to define and prioritize features, translating business requirements into technical specifications. I also managed the product roadmap, ensuring alignment with development efforts and customer needs. This experience has honed my ability to bridge the gap between technical and non-technical stakeholders, fostering a collaborative and efficient development process.',
        team: 'Collaboration was at the core of my role at E.ON Next. Working closely with cross-functional teams, including developers, designers, and stakeholders, I facilitated open communication channels to ensure everyone was aligned on project goals and timelines. I led daily stand-ups, sprint planning, and retrospectives, fostering a culture of continuous improvement and shared ownership. This collaborative approach not only enhanced team cohesion but also resulted in the successful delivery of high-quality digital products.',
        accomplishments: 'During my tenure at E.ON Next, I spearheaded several initiatives that resulted in significant achievements. These include reducing call volumes by 15%, increasing the mobile app rating from 1.1 to 4.8, and improving sentiment from -30 to +40. Additionally, we achieved a 20%+ increase in Net Promoter Score (NPS) across all key customer journeys. Our efforts were recognized with nominations for prestigious awards, including the CXA Innovation Award, Energy Supplier of the Year, and Best New Digital Team.',
        impact: 'These accomplishments had a profound impact. We reduced the debt book by 10% and provided assistance to thousands of customers in need. Our focus on enhancing self-serve options led to a 75% increase in self-serve actions, indicating a successful shift towards more efficient and convenient customer interactions.',
        external_link : 'https://www.eonnext.com/index.html',
        secondary_description : 'Leading transformative initiatives to enhance customer experiences and drive innovation in the energy sector, achieving significant reductions in call volumes, improved app ratings and sentiment, increased NPS, award nominations, debt reduction, and substantial growth in self-serve actions.',
        main_image: eon_main,
        sub1 : eon_sub_1,
        sub2 : eon_sub_2,
        sub3 : eon_sub_3,
    },
    {
        title: 'Lolly',
        description: 'Building marketing software to enhance connections between influencers and brands.',
        link: 'lolly',
        img_card: lolly_card,
        name: 'lolly',
        overview: 'Lolly, a newly formed startup, aimed to revolutionize influencer marketing. I led the development of multiple products, including an influencer marketing platform with four key sections: influencer search, outreach, campaign management, and reporting. Additionally, I contributed to an AI optimizing tool for social media ad campaigns.',
        scope: "At Lolly, my role involved scoping out and leading the development of the influencer marketing platform. I collaborated with stakeholders to define the platform's features and functionality, ensuring that it met the needs of our clients. I also contributed to the development of an AI optimizing tool for social media ad campaigns.",
        role: 'As a Software Developer and Product Manager at Lolly, I played a pivotal role in scoping out and leading the development of the influencer marketing platform. I collaborated closely with the existing customers, ensuring that the platform met the needs of our clients and was delivered on time and within budget. I also contributed to the development of an AI optimizing tool for social media ad campaigns.',
        team: "Building the team at Lolly was a significant accomplishment. I led the hiring process and grew the team from scratch, ensuring that each new member shared our beliefs and objectives. This cohesive team dynamic was crucial in delivering high-quality products and driving the company’s success.",
        accomplishments: 'During my time at Lolly, we achieved significant milestones, including acquiring 50 paying customers and facilitating over 1,000 connections between influencers and brands. We also led the company into a successful fundraising round, demonstrating the value and potential of our platform. Our customers were extremely satisfied with the platform, contributing to our growth and success.',
        impact: 'Our work at Lolly had a profound impact on the influencer marketing industry. We helped brands connect with over 1 million influencers on the platform and facilitated meaningful connections that led to successful marketing campaigns. Our platform also contributed to the growth of influencer marketing as a viable marketing strategy the brands we worked with.',
        external_link: 'https://www.lolly.com/',
        secondary_description: 'Leading the development of innovative marketing software, resulting in significant customer acquisition, successful fundraising, and meaningful connections between influencers and brands.',
        main_image: lolly_main,
        sub1: lolly_sub_1,
        sub2: lolly_sub_2,
        sub3: lolly_sub_3,
    },
    {
        title: 'Diverr',
        description : 'Designing and building a diving app that promotes sustainable scuba diving and snorkeling practices with marine animals',
        link : 'diverr',
        img_card: diverr_card,
        name :'diverr',
        overview: 'Diverr aims to promote sustainable scuba diving and snorkeling practices with marine animals. The app features a search section for users to find ethical swimming locations, a gamification element for tracking animal encounters, and a sharing section for users to post sightings and share with friends. The app will use user data to map animal locations and ethical ratings over time.',
        scope: "At its inception, Diverr serves as the foundation for a comprehensive app that will provide valuable insights for users seeking ethical marine animal encounters. The app aims to leverage user data and feedback to map locations, timing, and ethical practices for swimming with marine animals, offering a unique and informative resource for eco-conscious adventurers.",
        role: 'My role in Diverr included designing and building the app from concept to implementation. I led the development of key features, such as the search functionality, gamification elements, and sharing features. I am currently overseeing the approval process for releasing the app on the Apple App Store and Google Play Store.',
        secondary_description: "Pioneering a functional app that encourages ethical marine animal encounters and supports accurate, timely interactions.",
        main_image: diverr_main,
        sub1: diverr_sub_1,
        sub2: diverr_sub_2,
        sub3: diverr_sub_3,     
    }

]

export default ProductsData;