// VenturesData.js
import stuft_card from './VentureCardImages/stuft-card.png'
import orca_card from './VentureCardImages/orca-card.png'
import luuby_card from './VentureCardImages/luuby-card.png'

const VenturesData = [
  {
    title: 'Stuft',
    description: 'Classical Education is inefficient. Studying becomes difficult and time-pressured. Stuft maximises your chance of A-A* grades in the smallest amount of time.',
    link: 'https://stuft.co.uk/',
    logo: stuft_card,
    roles: ['Founder', 'Software Developer']
  },
  {
    title: 'Luuby',
    description: 'A Subscription as a Service that turns your Asana tickets into a Product roadmap that can be shared with key stakeholders in the customers business.',
    link: 'https://luuby.co.uk/',
    logo: luuby_card,
    roles: ['Founder', 'Software Developer']
  },
  {
    title: 'Orca Design',
    description: 'A digital agency focussing on bespoke software development and digital strategy as well as design, creative and user research.',
    link: 'https://orcadesign.co.uk/',
    logo: orca_card,
    roles: ['Founder', 'Software Developer']
  }
];

export default VenturesData;