// Ventures.js
import React from 'react';
import { Link } from "react-router-dom";
import VenturesData from "../components/VenturesData";
import Footer from "../components/Footer";
import './Ventures.css'

const Ventures = () => {
  return (
    <>
      <div className='Work-Grid'>
        <p className='work-grid-title'>Ventures I'm currently working on, both as a founder and software developer, outside of my professional career</p>
        <div className='grid-container'>
          {VenturesData.map((venture, index) => (
            <Link to={`${venture.link}`} key={index} className="grid-item" target="_blank">
              <img className='grid-img' src={venture.logo} alt={`Grid ${index + 1}`} />
              <div className='grid-summary'>
                <p className='grid-title'>{venture.title}</p>
                <p className='grid-description'>{venture.description}</p>
                <p className='grid-link'>Find out more</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer styling={'free'} />
    </>
  )
};

export default Ventures;