import React, { useState, useEffect } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import { getEvents } from './google-calendar';
import Lottie from 'lottie-react';
import Loading from '../components/animations/Loading.json';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faList } from '@fortawesome/free-solid-svg-icons';

import './Commitments.css'

function Commitments() {
  const [events, setEvents] = useState(null);
  const [error, setError] = useState(null);
  const [view, setView] = useState(window.innerWidth > 700 ? 'desktop' : 'mobile');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 700) {
        setView('desktop');
      } else {
        setView('mobile');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    getEvents()
      .then((events) => {
        setEvents(events);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const handleViewChange = () => {
    setView(view === 'desktop' ? 'mobile' : 'desktop' );
  };


  if (error) {
    return <p style={{ color: 'red' }}>Error: {error.message}</p>;
  }

  if (!events) {
    return (
      <div className='loading-animation'>
        <Lottie animationData={Loading} loop={true} style={{ width: '100px', height: '100px', marginBottom:"92.8px" }} />
      </div>
    );
  }

  return (
    <div className='whole-calendar'>
      <div className='toggle-button-container'>
        <ToggleButtonGroup value={view} exclusive onChange={handleViewChange}>
          <ToggleButton value="mobile">
            <FontAwesomeIcon icon={faList} className='toggle-icon' /> 
          </ToggleButton>
          <ToggleButton value="desktop">
            <FontAwesomeIcon icon={faCalendarAlt} className='toggle-icon' />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className='calendar-container'>
      {/* Desktop calendar view */}
      {view === 'desktop' && (
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          events={events}
          aspectRatio={1.2}
          responsive={true}
          expandRows={true}
        />
      )}

      {/* Mobile list view */}
      {view === 'mobile' && (
        <FullCalendar
          plugins={[listPlugin]}
          initialView='listWeek'
          events={events}
        />
      )}
      </div>
    </div>
  );
}

export default Commitments;