import './App.css';
import React from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProductPage from './pages/ProductPage';
import Home from './pages/Home.js';
import About from './pages/About.js';
import Products from './pages/Products.js';
import Ventures from './pages/Ventures';
import Navigation from './Navigation.js';
import Commitments from './pages/Commitments.js'
import Publications from './pages/Publications.js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


const App = () => {
  return (
          <div className="App">
              <Router>
                <ScrollToTop />
                <Navigation/>
                <Routes>
                  <Route path="/" element={<Home/>} />
                  <Route path="/products" element={<Products/>} />
                  <Route path="/products/:projectId" element = {<ProductPage/>}/>
                  <Route path="/ventures" element={<Ventures/>} />
                  <Route path="/commitments" element={<Commitments/>} />
                  <Route path="/publications" element={<Publications/>} />
                  <Route path="/about" element={<About/>} />
                </Routes>
              </Router>
          </div>
  )
}

export default App;
