import React from "react";
import profile from '../static/Profile.png'
import holiday from '../static/site2.jpeg'

import Footer from "../components/Footer";
import './About.css'
import data from '../data/about.js'

function About() {
    console.log(data)

    return (
      <>
      <div className='about-container'>
        <div className='about-1st'>
            <img className='about-img' src={profile} alt="Profile" width="160" height="160"></img>
            <h6 className='a-little'>A LITTLE ABOUT ME</h6>
            <h6 className='hi'>{data.greeting}</h6>
            <p className='aboutme'>{data.aboutme.aboutme1}</p>
            <p className='aboutme'>{data.aboutme.aboutme2}</p>
            <p className='aboutme'>{data.aboutme.aboutme3}</p>
            <p className='aboutme'>{data.aboutme.aboutme4}</p>
            <p className='aboutme'>{data.aboutme.aboutme5}</p>
            <h6 className='a-little'>EXPERIENCE</h6>
            <p className='experience-header'>Senior Technical Product Manager @ E.ON Next</p>
            <p className='experience-title'>Feb 2022 - Present </p>
            <p className='experience-header'>Lead Software Developer @ Lolly.com</p>
            <p className='experience-title'>June 2020 - Feb 2022 </p>
            <p className='experience-header'>Technology Management Consulting @ E&Y</p>
            <p className='experience-title'>July 2017 - June 2020 </p>
            <a className='download-cv' href="/Theo_Walcot_CV.pdf" download="Theo's CV">Download full resume here ↓</a>
        </div>
        <div className='about-2nd'>
            <h6 className='a-little'>MY VALUES</h6>
            <h5 className='value-header'>💛  Passion is everything.</h5>
            <h5 className='value'>I found that because I enjoy what I do, I have the drive and initiative to pour my heart into the things I believe in. I push for boundless creativity and to join other passionate people to make the world a happier place.</h5>
            <h5 className='value-header'>📒  Never stop learning.</h5>
            <h5 className='value'>I am always seeking challenges to continually develop myself, such as participating in competitions, collaborating on projects, taking courses, and involving myself in the technology community where I learn from others and also give back through mentorship and sharing my experiences and skills.</h5>
            <h5 className='value-header'>✊  Embrace the journey.</h5>
            <h5 className='value'>Embrace the learning curves and failures and discomfort. Open your mind and let curiosity and optimism drive you. Believe in yourself, and also have fun along the way.</h5>
            <h5 className='value-header'>😊  Be human.</h5>
            <h5 className='value'>Empathy and kindness go a long way. Share your knowledge and take time to understand different perspectives. Build towards a culture of inclusion and respect, and stay true to yourself and to others.</h5>
            <img className='img' src={holiday} alt='holiday'/>
        </div>
    </div>
    <Footer styling={'free'}/>
    </>
    );
  }

export default About;
