import React from "react";
import Footer from "../components/Footer";
import ProductsData from "../components/ProductsData";
import { useLocation } from "react-router-dom"; // Import useLocation
import { Link } from "react-router-dom";

import './ProductPage.css'

function ProductPage() {
    // Use useLocation to get the pathname
    const { pathname } = useLocation();
    const parts = pathname.split('/');
    const product_name = parts[parts.length - 1]; 
    
    const product = ProductsData.find((product) => product.name === product_name);

    if (!product) {
        // Handle the case where the product is not found
        return (
        <div className='Error'>
            <h6 className='Error-message'>Product not found</h6>
            <Link to='/products'><p className='Error-back'>Go back</p></Link>
        </div>
        )
    }

    else {
    return (
    <div className="ProductPage">
        <div className='title-section'>
            <div className='title-top'>
                <div className='left-title'>
                    <h1>{product.title}</h1>
                    <h2>{product.description}</h2>
                </div>
                <div className='right-title'>
                    <img src={product.main_image} alt='main' className={`product-main-image ${product.name}`} />
                </div>
            </div>
            <div className='title-lower-description'>
                <h3>{product.description}</h3>
                <p>{product.secondary_description}</p>
            </div>
        </div>
        <div className='photo-section'>
            <div className='photo-of-section'>
                <img src={product.sub1} alt='sub photos' />
            </div> 
            <div className='photo-of-section'>
                <img src={product.sub2} alt='sub photos' />
            </div> 
            <div className='photo-of-section'>
                <img src={product.sub3} alt='sub photos' />
            </div> 
        </div>
        <div className='desc-section'>
            <div className='section'>
            <div className='main-heading'>
                <h1>Context&nbsp;&nbsp;&nbsp;&nbsp;</h1>
            </div>
            <div className='desc-right'>
                <h2>📋  Overview</h2>
                <p>{product.overview} </p>
                <h2>👩‍💻  Scope</h2>
                <p>{product.scope}</p>
            </div>
            </div>
        </div>
        <div className='desc-section'>
            <div className='section'>
            <div className='main-heading'>
                <h1>Role and responsibilities</h1>
            </div>
            <div className='desc-right'>
                <h2>👷 My role</h2>
                <p>{product.role}</p>
                {product.team &&
                <>
                    <h2>⚽ Team collaboration</h2>
                    <p>{product.team}</p>
                </>
                }
            </div>
            </div>
        </div>
        <div className='desc-section'>
        { product.accomplishments &&
            <div className='section'>
                <div className='main-heading'>
                    <h1>Outcome&nbsp;</h1>
                </div>
                <div className='desc-right'>
                    <h2>🤗 Key Accomplishments</h2>
                    <p>{product.accomplishments}</p>
                    <h2>🥇 Impact</h2>
                    <p>{product.impact}</p>
                </div>
            </div>
        }
            <div className='button-container'>
            <button className='code-button'><a target='_blank' rel="noreferrer" href={product.external_link}>View Site</a></button>
            </div>
        </div>
        <div className='footer'>
            <Footer styling={'free'}/>
        </div>
        </div>
    );
    }
}

export default ProductPage;
