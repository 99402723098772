import React from 'react';
import { Link } from "react-router-dom";
import ProductsData from "../components/ProductsData";
import Footer from "../components/Footer";
import './Products.css'

const Products = ({footer}) => {
  return (
    <>
      <div className='Work-Grid'>
        <p className='work-grid-title'>Products I've built, both as a product manager and software developer, within my professional career</p>
        <div className='grid-container'>
          {ProductsData.map((product, index) => (
            <Link className='grid-item' to={`${product.link}`} key={index}>
              <img className='grid-img' src={product.img_card} alt={`Grid ${index + 1}`} />
              <div className='grid-summary'>
                <p className='grid-title'>{product.title}</p>
                <p className='grid-description'>{product.description}</p>
                <p className='grid-link'>Find out more</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer styling={'free'} />
    </>
  )
};

export default Products;