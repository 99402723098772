import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import './ResponsiveNavigation.css'

import { publications } from '../data/publications';



function ResponsiveNavigation({isNavExpanded, setIsNavExpanded}) {
  const { pathname } = useLocation();

  const links = ['products', 'ventures', 'commitments', 'publications', 'about'];

  const updatedLinks = publications.length === 0 ? links.filter(link => link !== 'publications') : links;


  return (
    <div className="ResponsiveNavigation">
      <button
        className='nav-responsive-item'
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        <FontAwesomeIcon
          icon={isNavExpanded ? faTimes : faBars}
          className='hamburger-icon'
        />
      </button>
      {isNavExpanded &&
        <ul className="nav-container">
          {updatedLinks.map((link) => (
            <li key={link} className={`res-nav-item ${pathname === `/${link}` ? 'res-back-active' : ''}`}>
              <NavLink className="res-nav-link" to={`/${link}`} onClick={() => {setIsNavExpanded(false)}}>
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </NavLink>
            </li>
          ))}
        </ul>
      }
    </div>      
  );
}

export default ResponsiveNavigation;