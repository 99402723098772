const data = {
    'greeting' : "Hi I'm Theo",
    'aboutme' : {
        'aboutme1' : "I'm a seasoned software developer, product expert, and entrepreneur with over 8 years of experience. My passion lies in building successful products and innovative technology for individuals and organizations driven by causes that aim to create a positive change for humanity and the world.",
        'aboutme2' : "I firmly believe in the transformative power of technology. It has the ability to enhance financial literacy of impoverished families, increase the survivability of endangered animals, and provide personalized care to those in need. Technology is undeniably one of the most crucial aspects of our modern world.",
        'aboutme3' : "In my career, I have spearheaded projects that have significantly impacted various sectors. For example, I optimized technology for global banks investing in third-world nations, resulting in lower fees and fairer lending practices for impoverished customers. I also collaborated with a sustainable energy provider to develop features that enhance transparency in energy spending and created solutions tailored to individuals burdened with heavy debt. Additionally, I have developed an app that helps tourists find ethical places and providers for swimming with marine animals, ensuring their well-being and protecting their migratory patterns.",
        'aboutme4' : "My work has been recognized with multiple awards, including being named a finalist for the prestigious Innovator of the Year at the Engage Awards 2023. Additionally, I have received accolades such as Best Customer Experience through Technology at the CX Awards and the Utility Provider of the Year.",
        'aboutme5' : "I am deeply committed to empowering clients in making a lasting impact through technology. I believe that with the right technology, we can truly benefit humanity and our planet."
    },
    'experience' : {
        'experience1' : ["Senior Product Manager @ E.ON Next","Feb 2022 - Present"],
        'experience2' : ["Lead Product Manager @ Lolly.com", "June 2020 - Feb 2022"],
        'experience3' : ["Technology Management Consulting @ E&Y", "July 2017 - June 2020"]
    },
    'value' : {
        'value1' : ["💛  Passion is everything.", "I found that because I enjoy what I do, I have the drive and initiative to pour my heart into the things I believe in. I push for boundless creativity and to join other passionate people to make the world a happier place."],
        'value2' : ["📒  Never stop learning.", "I am always seeking challenges to continually develop myself, such as participating in competitions, collaborating on projects, reading blogs, and involving myself in the design community where I learn from others and also give back through mentorship and sharing my experiences and skills."],
        'value3' : ["✊  Embrace the journey.", "Embrace the learning curves and failures and discomfort. Open your mind and let curiosity and optimism drive you. Believe in yourself, and also have fun along the way."],
        'value4' : ["😊  Be human.", "Empathy and kindness go a long way. Share our knowledge and take time to understand different perspectives. Build towards a culture of inclusion and respect, and stay true to yourself and to others."]
    }
}

export default data
